import { render } from "@testing-library/react";
import { Image, Tag } from "antd";
import axios from "axios";
import parse from 'html-react-parser';
import { create } from "zustand";
export const useProducts = create((set, get) => ({
    ed: {},
    setEd: (ed) => {
        set({ ed })
    },
    columns: [
        {
            title: 'Rasmi',
            dataIndex: 'image',
            key: 'image',
            width: 100,
            render: (text, record) => (<Image width={50} style={{ height: '50px', objectFit: 'cover' }} src={process.env.REACT_APP_MAINURL + record.image} />)
        },
        {
            title: 'Nomi UZ',
            dataIndex: 'name_uz',
            key: 'name_uz',
        },
        {
            title: 'Nomi RU',
            dataIndex: 'name_ru',
            key: 'name_ru',
        },
        {
            title: 'Batafsil UZ',
            dataIndex: 'description_uz',
            key: 'description_uz',
            render: (text, record) => parse(record.description_uz)
        },
        {
            title: 'Batafsil RU',
            dataIndex: 'description_ru',
            key: 'description_ru',
            render: (text, record) => parse(record.description_ru)
        },
        {
            title: 'Narxi',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (<>
                {record.sale_price ? <>
                    <del style={{ color: 'red' }}>{record.price} so'm</del>
                    <hr />
                    {record.sale_price} so'm
                </> : <>
                    {record.price} so'm
                </>}
            </>)
        },
        {
            title: "O'lchov birligi",
            dataIndex: 'unit_name',
            key: 'unit_name',
        },
        {
            title: 'Kategoriya',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: 'Belgilar',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, record) => (<>
                {record.is_featured ? <Tag color="indigo">Sevimli</Tag> : ''}
                {record.is_top_selling ? <Tag color="blue">TOPda</Tag> : ''}
                {record.is_popular ? <Tag color="green">Ommaviy</Tag> : ''}
                {record.is_new ? <Tag color="yellow">Yangi</Tag> : ''}
                {record.is_sale ? <Tag color="red">Chegirma</Tag> : ''}
            </>)
        },
        {
            title: 'SEO nomi',
            dataIndex: 'meta_title',
            key: 'meta_title',
        },
        {
            title: 'SEO kalitlari',
            dataIndex: 'meta_keywords',
            key: 'meta_keywords',
        },
        {
            title: 'SEO tavsifi',
            dataIndex: 'meta_description',
            key: 'meta_description',
        },
        //   ``, 
    ],
    products: [],
    setProducts: () => {
        axios.get('/products')
            .then(response => {
                set({ products: response.data })
            })
            .catch(error => {
                console.log(error)
            })
    },
    addProduct: (product) => {
        axios.post('/products', product)
            .then(response => {
                get().setProducts()
            })
            .catch(error => {
                console.log(error)
            })
    },
    editProduct: (product) => {
        axios.put(`/products/${product.id}`, product)
            .then(response => {
               if(response.status === 200){
                   get().setProducts()
               }
            })
            .catch(error => {
                console.log(error)
            })
    },
    deleteProduct: (id) => {
        axios.delete(`/products/${id.id}`)
            .then(response => {
                if (response.status === 200) {
                    get().setProducts()
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
}))